import { Injectable } from '@angular/core';
import { Plugin, Cordova, CordovaProperty, CordovaInstance, InstanceProperty, IonicNativePlugin } from '@ionic-native/core';
import { Observable } from 'rxjs';

@Plugin({
  pluginName: 'iosrtc',
  plugin: 'cordova-plugin-iosrtc', // npm package name, example: cordova-plugin-camera
  pluginRef: 'cordova.plugins.iosrtc', // the variable reference to call the plugin, example: navigator.geolocation
  repo: 'https://github.com/cordova-rtc/cordova-plugin-iosrtc', // the github repository URL for the plugin
  install: '', // OPTIONAL install command, in case the plugin requires variables
  installVariables: [], // OPTIONAL the plugin requires variables
  platforms: ['iOS'] // Array of platforms supported, example: ['Android', 'iOS']
})
@Injectable()
export class iosrtc extends IonicNativePlugin {

  /**
   * This function does something
   * @return {Promise<any>} Returns a promise that resolves when something happens
   */
  @Cordova()
  registerGlobals(): Promise<any> {
    return; // We add return; here to avoid any IDE / Compiler errors
  }

}
